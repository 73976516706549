import React from 'react';
import { PageProps, graphql } from 'gatsby';
import { WithTranslation, withTranslation, Trans } from 'react-i18next';
import GatsbyImage from 'gatsby-image';

import Layout, { LayoutOptions, HeroSectionColors } from '../../../components/Layout/Layout';
import { LocaleNameSpaceKeys } from '../../../localization/translations';
import { useLocaleContext } from '../../../context/Locale';
import { LocaleTag } from '../../../../config/locales';
import './style.scss';
import PageMenu from '../../../components/PageMenu/PageMenu';
import homeWarrantyResourcesMenu from '../../../data/page-menus/home-warranty-resources';
import CommonData from '../../../data/common/common';
import ButtonLink, { ButtonLinkSize } from '../../../components/ButtonLink/ButtonLink';
import { IconTypes } from '../../../components/Icon/Icon';

type Props = PageProps & WithTranslation;

const HomeWarranty: React.FC<Props> = (props) => {
  const { t, data } = props;
  const { activeLocaleTag } = useLocaleContext();

  const layoutOptions: LayoutOptions = {
    seo: {
      title: t('homeWarranty.seo.title'),
    },
    page: {
      title: t('homeWarranty.heading'),
    },
    heroSection: {
      options: {
        accentColor: HeroSectionColors.SKY_BLUE,
        heroImage: {
          fileName: 'hero-image-14.jpg',
          alt: 'Image',
        },
        blockText: t('homeWarranty.tagline'),
        shouldBeDigiBlock: true,
      },
    },
  };
  const homeWarrantyImg =
    activeLocaleTag === LocaleTag.EN
      ? (data as any).imageEN.childImageSharp.fluid
      : (data as any).imageFR.childImageSharp.fluid;

  const generateList = (list: string[]) => {
    return (
      <ul>
        {list.map((str, i) => {
          // eslint-disable-next-line react/no-danger
          return <li key={`${str}-${i}`} dangerouslySetInnerHTML={{ __html: str }} />;
        })}
      </ul>
    );
  };

  return (
    <Layout options={layoutOptions} className="HomeWarranty">
      <div className="grid-container">
        <div className="row row-wrap">
          <section className="column column-60">
            <p>
              <Trans i18nKey="homeWarranty.introParagraphs" t={t} />
            </p>
            <GatsbyImage
              alt={t('homeWarranty.summary')}
              fluid={homeWarrantyImg}
              style={{ position: 'relative', width: '100%' }}
              imgStyle={{ backgroundSize: 'cover', backgroundPosition: 'center' }}
            />
            <ButtonLink
              className="HomeWarranty__contact-rep-button"
              to={`mailto:${t(`${LocaleNameSpaceKeys.COMMON}:emails.encompass`)}`}
              linkOptions={{ isExternalLink: true }}
              iconOptions={{
                icon: IconTypes.MAIL,
              }}
              styleOptions={{
                isInline: true,
              }}
              ariaLabel={`${t(`${LocaleNameSpaceKeys.COMMON}:email`)} ${t(
                `${LocaleNameSpaceKeys.COMMON}:emails.encompass`
              )}`}
            >
              {t('homeWarranty.contactRepresentativeLabel')}
            </ButtonLink>
            <div className="HomeWarranty__generic-gap">
              <h2>{t('homeWarranty.claimsBlock.heading')}</h2>
              {generateList(t('homeWarranty.claimsBlock.list', { returnObjects: true }))}
            </div>
            <div className="HomeWarranty__generic-gap">
              <h2>{t('homeWarranty.eligibleSystemsBlock.heading')}</h2>
              {generateList(t('homeWarranty.eligibleSystemsBlock.list', { returnObjects: true }))}
            </div>
            <div className="HomeWarranty__generic-gap">
              <h2>{t('homeWarranty.programFee.heading')}</h2>
              {generateList(t('homeWarranty.programFee.list', { returnObjects: true }))}
            </div>
            <div className="HomeWarranty__generic-gap HomeWarranty__extra-info-list">
              {generateList(t('homeWarranty.extraInfoList', { returnObjects: true }))}
            </div>

            <div className="HomeWarranty__generic-gap HomeWarranty__learn-more-cta-block">
              <h3 className="h4">{t('homeWarranty.clickBelowToLearnMore')}</h3>
              <ButtonLink
                to={CommonData.websites.enCompassCertificateLink[activeLocaleTag]}
                linkOptions={{ isExternalLink: true }}
                iconOptions={{
                  icon: IconTypes.ARROW_NEXT,
                }}
                styleOptions={{
                  isInline: true,
                  size: ButtonLinkSize.SMALL,
                }}
              >
                {t('homeWarranty.encompassHomeServiceSite')}
              </ButtonLink>
            </div>
            <p className="small">
              <Trans i18nKey="homeWarranty.footerParagraphs" t={t} />
            </p>
          </section>
          <aside className="column column-33">
            <PageMenu
              className="HomeWarranty__generic-gap"
              menu={homeWarrantyResourcesMenu}
              menuTitle={t(`${LocaleNameSpaceKeys.COMMON}:pageMenuTitles.homeWarrantyResources`)}
            />
            <div className="HomeWarranty__testimonials">
              <h2>{t('homeWarranty.testimonialsBlock.heading')}</h2>
              <p>{t('homeWarranty.testimonialsBlock.description')}</p>
            </div>
          </aside>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    imageEN: file(relativePath: { eq: "home-warranty-en.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imageFR: file(relativePath: { eq: "home-warranty-fr.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export default withTranslation(LocaleNameSpaceKeys.CUSTOMER_CENTRE)(HomeWarranty);
